import React, {Component} from 'react';
import textureLogo from './../assets/images/texture-logo.png';
import cryptoCurrentLogo from './../assets/images/cryptocurrent-logo.png';
import { ReactComponent as IdcLogo } from './../assets/images/horizontal.svg';
export default class Header extends Component {
    render() {
        const client = window.client.toLowerCase();
        const className = `header-${client}`;
        return (
            <header className={className}>
                {client === 'idclear' && (<IdcLogo/>)}
                {client === 'texture' && (<img src={textureLogo} />)}
                {client === 'cryptocurrent' && ( 
                    <img 
                        src={cryptoCurrentLogo}
                        style={{
                            background: 'white',
                            padding: '0 15px 7px',
                            borderRadius: '16px',
                            maxWidth: '200px'
                        }}
                    />
                )}
            </header>
        );
    }
}

