import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "./Header";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { Container, Row, Col } from "react-bootstrap";
import { countries } from "../assets/countries/country-list";

class ErrorPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const errorText = window.errorText;
    return (
        <Fragment>
            <Header />
                <div className='body column capture_photo' style={{padding: "0 5% 0 5%"}}>
                    <div className="row description_container">
                      {errorText && <p className="description-title">
                        {errorText}
                      </p>}
                      {!errorText && <p className="description-title">
                        An unknown error occurred.
                      </p>}
                      {/* <p className="description-title">
                        Please close this browser tab and retry by clicking the link you received.
                      </p> */}
                    </div>
                    <div className="row description_container">
                      <p className="description">
                          If problems persist, please take a screenshot of this message and contact us at info@idclear.com
                      </p>
                    </div>
                </div>
        </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    instanceID: state.config.instanceID,
    orientation: state.idProperties.orientation,
    cardType: state.idProperties.cardType,
    sidesLeft: state.idProperties.sidesLeft,
    frontSubmitted: state.config.frontSubmitted,
    backSubmitted: state.config.backSubmitted,
  };
}
export default connect(mapStateToProps, null)(ErrorPage);
