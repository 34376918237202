import React, { Component, Fragment } from "react";
import axios from "axios";
import Header from "./Header";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  decrementSidesLeft,
  incrementSidesLeft,
  setCardOrientation,
} from "./actions/idPropertiesActions";
import { submitBackID, submitFrontID } from "./actions/configActions";
import Processing from "./Processing";
import sign from "jwt-encode";
//const sign = require('jwt-encode');
class ProcessedImageResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      submittingToApi: false,
    };
  }
  // ------------------ IDCLEAR - IMAGE CYCLE BLOCK ---------------------------
  proceedToNextStep() {
    // // console.log("SIDES_LEFT: " + this.props.sidesLeft);
    if (!window.ALL_SIDES_CAPTURED) {
      if (this.props.sidesLeft == 2) {
        /* Front image */
        // // console.log("----- IDCLEAR ------> Front image captured");
        window.imgBlob.front = this.dataURLToBlob(this.props.cardImage);
        window.imgBase64.front = this.props.cardImage;
        this.props.submitFrontID();

        if (window.details.document_type === "Identification: Passport") {
          //// console.log('set selfie capture true')
          window.SELFIE_CAPTURE = true;
          this.props.decrementSidesLeft();
        } else {
          this.props.setCardOrientation(1);
        }
        this.props.decrementSidesLeft();
        this.props.history.push("/capture/photo");
        // remove if reactivating backId capture
        //this.props.setCardOrientation(1); // add back if reactivating backId capture

        // COULD SEND JUST FRONT IMAGE TO YOUR SERVICE HERE
      } else if (this.props.sidesLeft == 1) {
        // add back if reactivating backId capture
        /* Back image */
        // // console.log("----- IDCLEAR ------> Back image captured");
        window.imgBlob.back = this.dataURLToBlob(this.props.cardImage);
        window.imgBase64.back = this.props.cardImage;
        window.SELFIE_CAPTURE = true;
        this.props.submitBackID();
        this.props.decrementSidesLeft();
        this.props.history.push("/capture/photo");
        // COULD SEND JUST BACK IMAGE TO YOUR SERVICE HERE
      } else if (this.props.sidesLeft == 0) {
        // TODO
        /* Selfie image */
        // // console.log("----- IDCLEAR ------> Selfie image captured");
        //window.imgBlob.selfie = this.dataURLToBlob(this.props.cardImage);
        window.imgBase64.selfie = this.props.cardImage;
        window.ALL_SIDES_CAPTURED = true;
        window.SELFIE_CAPTURE = false;

        // this forces the page to refresh and re-render as the final capture page
        this.forceUpdate();

        // COULD SEND JUST SELFIE IMAGE TO YOUR SERVICE HERE
      }
    } else if (window.ALL_SIDES_CAPTURED) {
      // SAMPLE APP LANDS HERE AND DOESNT PROGRESS AFTER ALL IMAGES ARE CAPTURED
      // THIS IS WHERE YOU'D PUT CODE TO DIRECT TO THE SCREEN/URL OF YOUR CHOICE
      // // console.log("----- IDCLEAR ------> All images captured");
      // COULD DO A LOOP TO SEND ALL IMAGES, OR SOMETHING ELSE DEPENDING ON HOW YOUR SERVICE WORKS
    }
  }

  


  doFinishingStep() {
    // console.log('Do finishing step');

    let imagesToUpload = [];
    Object.entries(window.imgBase64).map((item) => {
        if (item[1]) {
            imagesToUpload.push({
                name: item[0],
                data: item[1]
            });
        }
    });
    // console.log('Images to upload: ', imagesToUpload);

    const uploadFileToS3 = (presignedPostData, file) => {
        // console.log('uploading to s3');
        let retrySubmit = 5;
        //Object.keys(presignedPostData).forEach((key) => {});
        const formData = new FormData();
        Object.keys(presignedPostData.fields).forEach((key) => {
          formData.append(key, presignedPostData.fields[key]);
        });
  
        const blob = dataURItoBlob(file); //blob();
        formData.append("file", blob);
        const options = {
            method: "POST",
            body: formData,
        };
        // console.log('s3 request options: ', options);

       
        const sendCompleteNotice = () => {
            // console.log('complete kyc step...');
            let retry = 5;
            const {
              REACT_APP_USER_NAME,
              REACT_APP_PASSWORD,
              REACT_APP_IDC_ENDPOINT,
            } = process.env;
            const credentials = window.btoa(
              `${REACT_APP_USER_NAME}:${REACT_APP_PASSWORD}`
            );
            const secret = "secret";
            const imageInfo = [];
            Object.entries(window.imgBase64).map((item) => {
              if (item[1]) {
                imageInfo.push({
                  title: item[0],
                  documentcategory:
                    item[0] === "selfie"
                      ? "Liveness verification"
                      : window.details.document_type,
                });
              }
            });
            // console.log('uploaded images info to submit: ', imageInfo);
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + credentials,
              },
              body: JSON.stringify({
                country: window.details.countryofissue,
                secret: window.userId,
                images: imageInfo,
                terms_version: window.privacyVersion,
              }),
            };
            // console.log('build request options: ', requestOptions);
        
            const acknowledgeComplete = () => {
                // console.log('submit to api: ' + REACT_APP_IDC_ENDPOINT + "/external/kyc_complete");
                fetch(REACT_APP_IDC_ENDPOINT + "/external/kyc_complete", requestOptions)
                    .then((response) => {
                        // console.log('response: ', response);
                        if (response.status >= 200 && response.status < 300) {
                            // console.log('response ok, kyc completed');
                            return this.props.history.push("/thankyou");
                        } else {
                          return response.text().then(text => {throw new Error(text)});
                        }
                        // console.log('api failed after retries, throw error to catch: ', response.status);
                    })
                    .catch((e) => {
                        // console.log('caught error in complete_kyc:', e)
                        if (false) { //retry > 0
                            retry--;
                            // console.log('api failed, retries left:', retry);
                            return acknowledgeComplete();
                        } else {
                            this.setState({
                                ...this.state,
                                submittingToApi: false,
                            });
                            const errorText = "Your images were successfully uploaded, but we encountered an error in registering completion with the api."// + JSON.parse(e.message).detail;
                            window.errorText = errorText;
                            this.props.history.push("/loaderror");
                            //window.alert(e.message + " - Your images were uploaded, but we encountered an error in registering completion with the api.  Please contact us at help@idclear.com");
                        }
                    });
            }
        
            acknowledgeComplete();
        };

        const s3Upload = () => {
            // console.log('fetch url: ', presignedPostData.url);

            fetch(presignedPostData.url, options)
                .then((response) => {
                    // console.log('fetch response: ', response);

                    if (response.status >= 200 && response.status < 300) {
                        // console.log('response ok, upload successful');
                        imagesToUpload.shift();
                        if (imagesToUpload.length) {
                            // console.log('submit next image');
                            return submitImages(imagesToUpload);
                        } else {
                            // console.log('all images submitted, go to kyc_complete');
                            return sendCompleteNotice();
                        }
                    }
                    // console.log('throwing error to catch in s3Upload: ', response)
                    return response.text().then(text => {throw new Error(text)});
            })
            .catch((e) => {

                if (retrySubmit > 0) {
                    retrySubmit--;
                    // console.log('upload failed, retries left:', retrySubmit);
                    return s3Upload();
                } else {
                    this.setState({
                        ...this.state,
                        submittingToApi: false,
                    });
                    let errorText = "We encountered an error with the secure image upload. "; // + JSON.parse(e.message).detail;
                    // try {
                    //     errorText = "We encountered an error with the secure image upload. "; // + JSON.parse(e.message).detail;
                    // } catch (e) {
                    //     errorText = "Could not connect to the secure image server"
                    // }
                    window.errorText = errorText;
                    this.props.history.push("/loaderror");
                    //window.alert(e.message + " We encountered an error with the image upload. Please contact us at help@idclear.com.");
                }
            });
        }

        s3Upload();
  
        function dataURItoBlob(dataURI) {
          // convert base64 to raw binary data held in a string
          // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
          var byteString = atob(dataURI.split(",")[1]);
          var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          var blob = new Blob([ab], { type: mimeString });
          return blob;
        }
    };

    const submitImages = (images) => {
        // console.log('submitting images');
        this.setState({
          ...this.state,
          submittingToApi: true,
        });
        //retrySubmit = 5;
        
        const file = images[0].data;
        // console.log('Image to upload: ', images[0]);
        let signedUrlData;
        try {
            signedUrlData = window.signedUrls.find(url => url.name === images[0].name);
        } catch (e) {
            window.errorText = "Could not find pre-signed url data"
            this.props.history.push("/loaderror");
        }
        if (!signedUrlData) {
            window.errorText = "Could not find pre-signed url data"
            this.props.history.push("/loaderror");
        }

        // console.log('presigned URL for image: ', signedUrlData);
        uploadFileToS3(signedUrlData, file);
    };

    submitImages(imagesToUpload);
  }

  dataURLToBlob(canvasDataURL) {
    let binary = atob(canvasDataURL.split(",")[1]);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpg" });
  }

  retryPhoto() {
    this.props.history.push("/capture/photo", { isRetry: true });
  }

  renderTitleText() {
    if (this.props.blurry) return "Image appears blurry. Please retry.";
    if (this.props.hasGlare) return "Image has glare. Please retry.";
    if (window.ALL_SIDES_CAPTURED) return "Image capture complete.";
    if (window.SELFIE_CAPTURE)
      return "Ensure your selfie is of the shoulders and head only.";
    return "Ensure all text is visible.";
  }

  render() {
    if (this.state.processing) {
      return <Processing />;
    }
    return (
      <Fragment>
        <Header />

        <div className="body column capture_photo">
          {this.props.blurry && (
            <div className="column description_container">
              <img
                alt="idscango"
                className="icon"
                src={require("../assets/images/icon_attention@2x.png")}
              />
              <p className={"description error"}>{this.renderTitleText()}</p>
            </div>
          )}

          <div className="row wrapper description_container">
            {!this.props.blurry && (
              <p className={"description"}>{this.renderTitleText()}</p>
            )}
          </div>

          <div className="capture_group">
            <div className="row wrapper capture_container">
              {this.props.cardImage && !window.ALL_SIDES_CAPTURED && (
                <img
                  alt={"idscango"}
                  src={this.props.cardImage}
                  className="capture"
                />
              )}
            </div>
            {window.ALL_SIDES_CAPTURED && (
              <Fragment>
                <div className="row wrapper capture_container">
                  {window.imgBase64.front && (
                    <img
                      alt={"idscango"}
                      src={window.imgBase64.front}
                      className="capture"
                    />
                  )}
                </div>

                <div className="row wrapper capture_container">
                  {window.imgBase64.back && (
                    <img
                      alt={"idscango"}
                      src={window.imgBase64.back}
                      className="capture"
                    />
                  )}
                </div>

                <div className="row wrapper capture_container">
                  {window.imgBase64.selfie && (
                    <img
                      alt={"idscango"}
                      src={window.imgBase64.selfie}
                      className="capture"
                    />
                  )}
                </div>
              </Fragment>
            )}
            <div className="wrapper column capture_controls">
              {!window.ALL_SIDES_CAPTURED ? (
                <Fragment>
                  <a className={"btn"} onClick={() => this.proceedToNextStep()}>
                    <p className={"buttonBgText"}>Continue with this image</p>
                  </a>
                  <div
                    className={"btn outline"}
                    onClick={() => this.retryPhoto()}
                  >
                    <p className={"buttonBdText"}>Retry</p>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  {!this.state.submittingToApi && (
                    <a className={"btn"} onClick={() => this.doFinishingStep()}>
                      <p className={"buttonBgText"}>Finish</p>
                    </a>
                  )}
                  {this.state.submittingToApi && (
                    <a className={"btn"}>
                      <p className={"buttonBgText"}>Uploading images...</p>
                    </a>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  // // console.log('mapStoP: ', state)
  return {
    instanceID: state.config.instanceID,
    orientation: state.idProperties.orientation,
    cardType: state.idProperties.cardType,
    sidesLeft: state.idProperties.sidesLeft,
    frontSubmitted: state.config.frontSubmitted,
    backSubmitted: state.config.backSubmitted,
    cardImage: state.captureProperties.image.data,
    blurry:
      state.captureProperties.sharpness < 50 &&
      state.captureProperties.sharpness >= 0,
    hasGlare:
      state.captureProperties.glare < 50 && state.captureProperties.glare >= 0,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      decrementSidesLeft,
      incrementSidesLeft,
      setCardOrientation,
      submitFrontID,
      submitBackID,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessedImageResult);
