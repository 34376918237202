import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Header from "./Header";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { Container, Row, Col } from "react-bootstrap";
import { countries } from "../assets/countries/country-list";

class DetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        country: null,
        type: null
    };
    this.countryOptions = countries.map(country => ({label: country.name, code: country.iso2}));
    // this.countryOptions = Country.getAllCountries().map((country) => {
    //     return {
    //         label: country.name,
    //         ...country,
    //     };
    // });
    this.docOptions = [
        {label: "Passport", val: "Identification: Passport"},
        {label: "Driving licence", val: "Identification: Driving licence"},
        {label: "National / government identity card", val: "Identification: National / government identity card"},
        // {label: "Residence Permit", val: 'Identification: National / government identity card'},
        // {label: "Utility Bill", val: "Proof of address: Utility bill"},
    ];
  }

  componentDidMount() {}

  getOrientationCopy() {
    return this.props.orientation === 0 ? "front" : "back";
  }

  confirmDetails() {
    //localStorage.setItem('acuantEula', true);
    window.details = {
        countryofissue: this.state.country.code,
        document_type: this.state.type
    }
    // console.log('confirm details: ', window.details);
    this.props.history.replace("/capture/photo");
  }

  selectCountry(country) {
    // console.log('select country: ', country);
    this.setState({
        ...this.state,
        country
    })
  }

  selectType(type) {
    this.setState({
        ...this.state,
        type
    })
  }

  render() {
    const si = <SearchIcon />;
    const client = window.client;

    return (
      <Fragment>
        <Header />
        <div
          className="body column capture_photo"
          style={{ padding: "0 5% 0 5%" }}
        >
            {client.toLowerCase() !== 'texture' && (
              <div className="row description_container">
                  <p className="description-title">
                  {`Welcome to ${client} verification`}
                  </p>
              </div>
            )}
            <div className="row description_container">
                <p className="description">
                  Please select the document type and country of issue of the document you will be uploading
                </p>
            </div>
         
          <Container>
            <Row>
              <Autocomplete
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  if (!!newValue) {
                    this.selectType(newValue.val)
                  } else {
                    this.selectType(null)
                  }
                }}
                sx={{
                  marginTop: "3em",
                  //marginRight: '.5em',
                  borderColor: "red",
                }}
                className="optSelect"
                name="documentType"
                id="documentType"
                options={this.docOptions}
                popupIcon={si}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Document type"
                  />
                )}
              />
            </Row>
            <Row>
              <Autocomplete
                onChange={(event, newValue) => {
                    // console.log(newValue);
                    this.selectCountry(newValue)
                }}
                sx={{
                  marginTop: "3em",
                  marginBottom: "3em",
                  //marginLeft: '.5em',
                  borderColor: "red",
                }}
                className="optSelect"
                name="countryOfIssue"
                id="countryOfIssue"
                options={this.countryOptions}
                popupIcon={si}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Country of issue"
                  />
                )}
              />
            </Row>
          </Container>

          <Fragment>
            {this.state.country && this.state.type && (
              <label className="btn" onClick={() => this.confirmDetails()}>
                <p className={"buttonBgText"}>Continue</p>
              </label>
            )}
          </Fragment>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    instanceID: state.config.instanceID,
    orientation: state.idProperties.orientation,
    cardType: state.idProperties.cardType,
    sidesLeft: state.idProperties.sidesLeft,
    frontSubmitted: state.config.frontSubmitted,
    backSubmitted: state.config.backSubmitted,
  };
}
export default connect(mapStateToProps, null)(DetailsPage);
