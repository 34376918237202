import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Processing from "./Processing";
import {setCaptured} from "./actions/capturedActions";
import {bindActionCreators} from "redux";
import Header from './Header';

class AcuantReactCamera extends Component {
  constructor(props) {
    super(props);
    this.detectedCount = 0;
    this.state = {
      processing: false,
      liveCaptureFailed: false
    }
  }

  setProcessing(value) {
    this.setState({
      ...this.state,
      processing: value
    })
  }

  setLiveCaptureFailed(value) {
    this.setState({
      ...this.state,
      liveCaptureFailed: value
    })
  }

  imageDataToImage(imagedata) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = imagedata.width;
    canvas.height = imagedata.height;
    ctx.putImageData(imagedata, 0, 0);
 
    var image = new Image();
    return canvas.toDataURL();
 }

  onCaptured(_) {
    // console.log("----- IDCLEAR ------> Analyzing image...");
    //document captured
    //this is not the final result of processed image
    //show a loading screen until onCropped is called
    if(window.SELFIE_CAPTURE){ 
      let b64string = this.imageDataToImage(_.data);
      let imgObj = {
        cardtype: 1,
        dpi: 400,
        glare: 99,
        sharpness: 99,
        moire: 100,
        moireraw: 100,
        image: {
          bytes: _.data.data,
          data: b64string,
          height: _.data.height,
          width: _.data.width
        }
      };
      this.props.setCaptured(imgObj);
      this.props.history.push('/photo/confirm');
    }
  }

  onCropped(response) {
    if(!window.SELFIE_CAPTURE){
      // console.log("----- IDCLEAR ------> Cropping image...");
      // console.log('croppedResponse', response)
      if (response) {
        //use response
        this.props.setCaptured(response);
        this.props.history.push('/photo/confirm');
      }
      else {
        //cropping error
        //restart capture if not all sides are captured
        if(!window.ALL_SIDES_CAPTURED){
          this.startCamera()
        }
      }
    }
  }

  onFrameAvailable(_) {
    //do nothing
  }

  startCamera(){
    //console.log('startCamera, check selfieCapture: ', window.SELFIE_CAPTURE)
    if (window.AcuantCameraUI && !window.ALL_SIDES_CAPTURED) {
      console.log("----- IDCLEAR ------> Camera started...");
      console.log("Camera supported? ", window.AcuantCamera.isCameraSupported);
      //this.setProcessing(true);
      if (window.AcuantCamera.isCameraSupported && !window.SELFIE_CAPTURE) { // && !window.SELFIE_CAPTURE
        window.AcuantCameraUI.start({
          onCaptured: this.onCaptured.bind(this), 
          onCropped: this.onCropped.bind(this), 
          onFrameAvailable: this.onFrameAvailable.bind(this)
        }, this.onError.bind(this));
      }
      else {
        console.log('camera not supported')
        this.startManualCapture();
      }
      //this.startManualCapture();
    }
  }

  startManualCapture() {
    console.log('start manual capture')
    window.AcuantCamera.startManualCapture({
      onCaptured: this.onCaptured.bind(this),
      onCropped: this.onCropped.bind(this)
    }, this.onError.bind(this));
    //this.setProcessing(false);
  }

  onError(_, code) {
    if (code === "repeat-fail") {
      this.setLiveCaptureFailed(true);
    } else if (code === "sequence-break") {
      alert("Live Capture failed. Please try again.")
      this.props.history.replace("/capture/photo")
    } else {
      alert("This device does not support Live Capture. Launch manual capture.")
      this.props.history.replace("/capture/photo")
    }
  }

  componentDidMount() {
    this.startCamera();
  }
  componentWillUnmount() {
  }

  render() {
    if (this.state.processing) {
      return <Processing />
    } else if (this.state.liveCaptureFailed) {
      return (
        <Fragment>
          <Header />
          <div className='body column'>
            <div className='row wrapper icon' />
            <div className='row wrapper description_container'>
              <p className='description'>Live camera failed. </p>
            </div>
            <div className="wrapper column">
              <label className='btn' onClick={() => this.startManualCapture()}>
                <p className={'buttonBgText'}>Start manual capture</p>
              </label>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return (
        <div id="acuant-camera"></div>
      )
    }

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCaptured }, dispatch);
}

export default connect(null, mapDispatchToProps)(AcuantReactCamera);
