import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "./Header";
import { decrementSidesLeft, setCardOrientation, setCardType } from "./actions/idPropertiesActions";
import { setInstanceID, submitBackID, submitFrontID } from "./actions/configActions";
import Processing from "./Processing";

class CapturePhoto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: null,
            processing: false        
        }
    }

    isIEorEDGE() {
        return navigator.appName === 'Microsoft Internet Explorer' || (navigator.appName === "Netscape" && navigator.appVersion.indexOf('Edge') > -1);
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state) {
            if (this.props.location.state.isRetry) {
                this.navigateCamera();
            }
        }
    }

    getOrientationCopy() {
        return this.props.orientation === 0 ? 'front' : 'back';
    }

    openCamera(type){
        this.props.setCardType(type);
        this.navigateCamera();
    }

    navigateCamera(){
        this.props.history.push('/capture/camera');
    }

    render() {
        if (this.state.processing) {
            return <Processing />
        }
        return (
            <Fragment>

                <Header />
                <div className='body column capture_photo'>
                    {this.props.sidesLeft > 0
                        ? 
                        <Fragment>
                            <div className='row wrapper description_container'>
                                <p className='description'>Upload a clear picture of the {this.getOrientationCopy()} of your identity document.</p>
                                <p className='description' style={{marginTop: '1em'}}>Rotate your phone anti-clockwise to a horizontal position to take the photo.</p>

                        </div>

                            <div className="capture_group">

                                <div className='row wrapper capture_container'>


                                    {this.props.sidesLeft === 2 && /* set to 2 if reactivating backId capture */
                                    <img alt='idscango' className={'capture'} src={require('../assets/video/scan_process.gif')} />

                                    }
                                    {this.props.sidesLeft === 1 && // add back if reactivating backId capture
                                        <img alt='idscango' className={'capture'} src={this.props.frontSubmitted ? require('../assets/images/card_back@3x.png') : require('../assets/images/illustration1@3x.png')} />
                                    }
                                </div>

                                <div className="wrapper column capture_controls">

                                    {this.props.sidesLeft === 2 && /* set to 2 if reactivating backId capture */
                                        <Fragment>
                                            {process.env.REACT_APP_IDPASSPORT_ENABLED === 'true' &&
                                                <label className='btn' onClick={() => this.openCamera(1)}>
                                                    <p className={'buttonBgText'}>Capture ID/Passport</p>
                                                </label>
                                            }
                                        </Fragment>
                                    }

                                    {this.props.sidesLeft === 1 && // add back if reactivating backId capture
                                        <label className={'btn'} onClick={() => this.openCamera(this.props.cardType)} >
                                            <p className='buttonBgText'>Capture {this.getOrientationCopy()} of ID Card</p>
                                        </label>
                                    }

                                </div>

                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <div className='row wrapper description_container'>
                                <p className='description'>Take a selfie image using the front camera of your device.</p>
                            </div>

                            <div className="capture_group">

                                <div className='row wrapper capture_container'>
                                    <img alt='idscango' className='capture' src={require('../assets/images/illustration2@3x.png')} />
                                </div>

                                <div className="wrapper column capture_controls">

                                    <label className={'btn'} onClick={() => this.openCamera(this.props.cardType)}>
                                        <p className={'buttonBgText'}>Take selfie image</p>
                                    </label>

                                </div>

                            </div>
                        </Fragment>
                    }
                </div>



            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        orientation: state.idProperties.orientation,
        cardType: state.idProperties.cardType,
        sidesLeft: state.idProperties.sidesLeft,
        frontSubmitted: state.config.frontSubmitted,
        backSubmitted: state.config.backSubmitted
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCardType, setInstanceID, setCardOrientation, decrementSidesLeft, submitFrontID, submitBackID }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CapturePhoto);