import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Header from "./Header";

class ThankYouPage extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        window.localStorage.removeItem('sdkUserId');
    }

    getOrientationCopy() {
        return this.props.orientation === 0 ? 'front' : 'back';
    }

    render() {
        return (
            <Fragment>
                <Header />
                    <div className='body column capture_photo' style={{padding: "0 5% 0 5%"}}>
                        <div className="row description_container">
                        <p className="description-title">
                            Thank you!
                            </p>
                        </div>
                        {/* <div className="row description_container">
                            <p className="description">
                               You may now return to idclear onboarding.
                            </p>
                        </div> */}
                    </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        orientation: state.idProperties.orientation,
        cardType: state.idProperties.cardType,
        sidesLeft: state.idProperties.sidesLeft,
        frontSubmitted: state.config.frontSubmitted,
        backSubmitted: state.config.backSubmitted
    };
}
export default connect(mapStateToProps, null)(ThankYouPage);