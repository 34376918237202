import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Header from "./Header";
class EulaPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            consent: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    componentDidMount() {
  
    }

    getOrientationCopy() {
        return this.props.orientation === 0 ? 'front' : 'back';
    }

    confirmEula(){
        localStorage.setItem('acuantEula', true);
        window.privacyVersion = this.state.consent ? 1000.0 : null;
        this.props.history.replace('/details');
    }

    render() {
        const name = window.client;
        return (
            <Fragment>
                <Header />
                    <div className='body column capture_photo' style={{padding: "0 5% 0 5%"}}>
                    <div className="row description_container">
                        <p className="description-title">
                        {name === 'Texture' ? `Welcome to Texture’s contributor verification` : `Welcome to ${name} verification`}
                        </p>
                    </div>
                        <div style={{margin: "1em 0 2em 0"}}>
                            <p style={{marginBottom: "1em", lineHeight: "1.5"}}>In order to verify your identity we require your consent to process your personal data, including biometric data.</p>

                            <p style={{marginBottom: "3em",lineHeight: "1.5"}}>If you’d like more information on how your data is processed, you can find the idclear Privacy Notice <a href="https://idclear.com/privacy-policy/" target="_blank">here</a> and the Texture Privacy Policy <a href="https://kyc.texture.idclear.com/policy.pdf" target="_blank">here</a>.</p>

                            <form>
                                <label style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                    <input
                                        name="consent"
                                        type="checkbox"
                                        checked={this.state.consent}
                                        onChange={this.handleInputChange} 
                                        style={{
                                            marginRight: "1em"
                                        }}
                                    />
                                    I give consent
                                
                                </label>
                                <br />
                               
                            </form>
                        </div>
                        <Fragment>
                            <button className='btn' disabled={!this.state.consent} onClick={() => this.confirmEula()}>
                                <p className={'buttonBgText'}>Accept</p>
                            </button>
                        </Fragment>
                    </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        orientation: state.idProperties.orientation,
        cardType: state.idProperties.cardType,
        sidesLeft: state.idProperties.sidesLeft,
        frontSubmitted: state.config.frontSubmitted,
        backSubmitted: state.config.backSubmitted
    };
}
export default connect(mapStateToProps, null)(EulaPage);