import React, {Component} from 'react';
import '@babel/polyfill';
import {Switch, Route, Redirect} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import {PersistGate} from 'redux-persist/es/integration/react';
import {ConsoleView, isMobile} from "react-device-detect";
import {Provider} from 'react-redux';
import CapturePhoto from './screens/CapturePhoto';
import EulaPage from './screens/Eula';
import ThankYouPage from './screens/ThankYou';
import ErrorPage from './screens/Error';
import DetailsPage from './screens/Details';
import CaptureSelfie from './screens/CaptureSelfie';
import Results from './screens/Results/index';
import ErrorComponent from './screens/Error/index';
import "./styles/main.css";
import ProcessedImageResult from "./screens/ProcessedImageResult";
import AcuantReactCamera from "./screens/AcuantReactCamera";

/*
global Raven
 */

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAcuantSdkLoaded: false
        }
        this.isInitialized = false;
        this.isIntializing = false;
        // this.onFail = this.onFail.bind(this);
        // this.onSuccess = this.onSuccess.bind(this);
        
        // ------------------ IDCLEAR GLOBALS -------------------------
        window.imgBlob = {
            front: null,
            back: null,
            selfie: null
        };
        window.imgBase64 = {
            front: null,
            back: null,
            selfie: null
        };
        window.details = {
            countryOfIssue: null,
            documentType: null
        }
        window.SELFIE_CAPTURE = false;
        window.ALL_SIDES_CAPTURED = false;
        const queryParams = new URLSearchParams(window.location.search);

        if (queryParams.get('id')) {
            window.localStorage.setItem('sdkUserId', queryParams.get('id'))
        } 
        window.userId = window.localStorage.getItem('sdkUserId');
        if (!window.userId) {
            //window.alert('User id not found.')
            window.errorText = "User ID not found. Please close this browser tab and retry by clicking the link you received."
            this.props.routerHistory.push('/loaderror');
        }

        // // console.log('user id: ', window.userId)
        window.client = process.env.REACT_APP_CLIENT_CONFIG;
    }

    componentDidMount() {
        if (process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID && process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID.length > 0) {
            Raven.config(process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID).install();
        }

        if (process.env.REACT_APP_MOBILE_ONLY === 'true') {
            if (!isMobile) {
                this.props.routerHistory.replace('/error/mobileonly');
                document.body.classList.add('mobile-only');
                this.setState({isAcuantSdkLoaded: true});
            } else {
                if (!this.props.config) {
                    this.props.routerHistory.replace('/');
                }
                this.loadScript();
            }
        } else {
            if (!this.props.config) {
                this.props.routerHistory.replace('/');
            }
            this.loadScript();
        }
    }

    loadScript() {
        const sdk = document.createElement("script");
        sdk.src = "AcuantJavascriptWebSdk.min.js";
        sdk.onload = () => window.loadAcuantSdk();
        window.onAcuantSdkLoaded = () => this.initialize();
        document.body.appendChild(sdk);

        const camera = document.createElement("script");
        camera.src = "AcuantCamera.min.js";
        document.body.appendChild(camera);

        const passiveLiveness = document.createElement("script");
        passiveLiveness.src = "AcuantPassiveLiveness.min.js";
        document.body.appendChild(passiveLiveness);
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID && process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID.length > 0) {
            Raven.captureException(error, {extra: errorInfo});
        }
        window.alert(errorInfo);
        this.props.routerHistory.push('/error/default');
    }

    initialize() {
        if (!this.isInitialized && !this.isIntializing) {
            this.isIntializing = true;

            window.AcuantJavascriptWebSdk.initialize(
                (function() {
                    if (process.env.NODE_ENV === 'development') {
                        return btoa(`${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_PASSWORD}`);
                    } else {
                        return process.env.REACT_APP_AUTH_TOKEN;
                    }
                })(), 
                process.env.REACT_APP_ACAS_ENDPOINT,
                {
                    onSuccess: function() {
                        if (!this.isOldiOS()) {
                            window.AcuantJavascriptWebSdk.startWorkers(this.initDone.bind(this));
                        } else {
                            window.AcuantJavascriptWebSdk.startWorkers(this.initDone.bind(this), [window.AcuantJavascriptWebSdk.ACUANT_IMAGE_WORKER]);
                        }
                    }.bind(this),

                    onFail: function() {
                        this.isIntializing = false;
                        this.setState({
                            isAcuantSdkLoaded: true
                        })
                    }.bind(this)
                });
        } 
    }

    isOldiOS() {
        let ua = navigator.userAgent;
        let keyPhrase = "iPhone OS";
        const keyPhrase2 = "iPad; CPU OS";
        let index = ua.indexOf(keyPhrase);
        if (index < 0) {
            keyPhrase = keyPhrase2;
            index = ua.indexOf(keyPhrase);
        }
        if (index >= 0) {
            let version = ua.substring(index + keyPhrase.length + 1, index + keyPhrase.length + 3);
            try {
                let versionNum = parseInt(version);
                if (versionNum && versionNum < 13) {
                    return true;
                } else {
                    return false;
                }
            } catch (_) {
                return false;
            }
        } else {
            return false;
        }
    }


    onFail() {
        this.isIntializing = false;
        this.setState({
            isAcuantSdkLoaded: true
        })
        this.props.routerHistory.push('/loaderror')
    }

    onSuccess() {
        this.setState({
            isAcuantSdkLoaded: true
        })
    }

    initDone() {
        this.isInitialized = true;
        this.isIntializing = false;
        
        const getPresignedUrls = () => {
            const {REACT_APP_USER_NAME, REACT_APP_PASSWORD, REACT_APP_IDC_ENDPOINT} = process.env;
            const credentials = window.btoa(`${REACT_APP_USER_NAME}:${REACT_APP_PASSWORD}`);
            const data = {
                name: window.userId,
            };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + credentials
                },
                body: JSON.stringify(data)
            };
            let retry = 5;
            const fetchPresignedUrls = () => {
                fetch(REACT_APP_IDC_ENDPOINT + '/external/get_signed_urls', requestOptions)
                    .then(response => {
                        // console.log('URL RESPONSE: ', response)
                        if (response.status >= 200 && response.status < 300) { //
                            // console.log('response ok', response)
                            return response.json();
                        }
                        
                        if (response.status == 429) {
                            throw new Error(response.status);
                        }

                        return response.text().then(text => {throw new Error(text)});
                    })
                    .then((res) => {
                        // console.log('response ok, fetched urls: ', res);
                        this.onSuccess();
                        window.signedUrls = res;
                    })
                    .catch(e => {
                        // console.log('caught error:', e);
                        // console.log(e);

                        const retryUrls = () => {
                            if (retry > 0) {
                                retry--;
                                // console.log('(error) api failed to get urls, retries left:', retry);
                                return fetchPresignedUrls();
                            } else {
                                //throw new Error(response.status);
                                let errorText = "Could not connect to the remote server to fetch presigned data";
                                // try {
                                //     errorText = JSON.parse(e.message).detail;
                                // } catch (e) {
                                //     errorText = "Could not connect to the remote server"
                                // }
                               
                                //window.alert(errorText + ' (get_signed_urls)');
                                // console.log('err text: ', errorText)
                                window.errorText = errorText;
                                this.onFail();
                            }
                        }

                        if (e == 429) {
                            // console.log('(error) 429 response, wait to retry... ')
                            setTimeout(retryUrls, 5000);
                        } else {
                            retryUrls();
                        }
                    });
            }

            fetchPresignedUrls();
        }

        if (window.userId) {
            getPresignedUrls();
        } else {
            this.onFail();
        }
    }

    

    render() {

        const client = window.client.toLowerCase();
        const defaultRoute = client === 'texture' ? 'eula' : 'details';
        return (
            <div className={'mainContent'}>
                {
                    this.state.isAcuantSdkLoaded && <Provider store={this.props.store}>
                        <PersistGate loading={null} persistor={this.props.persistor}>
                            <ConnectedRouter history={this.props.routerHistory}>
                                <Switch>
                                    <Redirect exact from="/" to={defaultRoute}/>
                                    <Route path='/eula' exact component={EulaPage}/>
                                    <Route path='/loaderror' exact component={ErrorPage}/>
                                    <Route path='/thankyou' exact component={ThankYouPage}/>
                                    <Route path='/details' exact component={DetailsPage}/>
                                    <Route path="/capture/photo" exact component={CapturePhoto}/>
                                    <Route path="/capture/camera" exact component={AcuantReactCamera}/>
                                    <Route path="/photo/confirm" exact component={ProcessedImageResult} />
                                    <Route path="/capture/selfie" exact component={CaptureSelfie}/>
                                    <Route path='/results' component={Results}/>
                                    <Route path="/error" component={ErrorComponent}/>
                                </Switch>
                            </ConnectedRouter>
                        </PersistGate>
                    </Provider>
                }
            </div>
        );
    }
}

export default App;
